import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Parallax } from 'react-parallax';
import './App.css';

const DogWifHat = () => {
  const highlightRef = useRef(null);
  const strikethroughRef = useRef(null);

  useEffect(() => {
    // Animate highlighted text
    highlightRef.current.classList.add('animate-highlight');
    // Animate strikethrough text
    strikethroughRef.current.classList.add('animate-strikethrough');
  }, []);

  const image2Ref = useRef(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleSliderChange = () => {
      const sliderValue = sliderRef.current.value;
      image2Ref.current.style.clipPath = `polygon(0 0, ${sliderValue}% 0, ${sliderValue}% 100%, 0 100%)`;
    };

    sliderRef.current.addEventListener('input', handleSliderChange);

    return () => {
      sliderRef.current.removeEventListener('input', handleSliderChange);
    };
  }, []);

  useLayoutEffect(() => {
    const handleSliderChange = () => {
      const sliderValue = sliderRef.current.value;
      image2Ref.current.style.clipPath = `polygon(0 0, ${sliderValue}% 0, ${sliderValue}% 100%, 0 100%)`;
    };
  
    // Apply the clip path when the component mounts
    handleSliderChange();
  
    sliderRef.current.addEventListener('input', handleSliderChange);
  
    return () => {
      sliderRef.current.removeEventListener('input', handleSliderChange);
    };
  }, []);

  return (
    <div>
      <div className="flex flex-col max-w-full bg-black">

        {/* Infinite scrolling header */}
        <div className="fixed z-10 overflow-x-hidden bg-gray-800">
          <div className="animate-marquee whitespace-nowrap p-8">
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide mr-16 font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
            <span className="text-3xl text-white lowercase tracking-wide font-mono"><b>▶ i mean bro, it's literally trump wif a hat</b></span>
          </div>

          {/* HEADER LINKS */}
          <div className="fixed w-full z-10 overflow-x-hidden bg-gray-800">
            <div className="w-full flex flex-row items-center justify-center space-x-16 max-sm:space-x-0 max-sm:px-4 mb-8">
              <a href="https://raydium.io/swap?outputCurrency=DDGcYJkMMD1iiLRfPQLZePxLJCLDhiioQ83frmdAJd3h" target="blank" className="hover:text-orange-500 text-2xl text-white font-bold font-mono tracking-wide text-center max-sm:tracking-tight">BUY WIF US</a>
              <a href="https://dexscreener.com/solana/6pfbnfwbsctz7xpekeupyxwrp5utrdpmdhm47r11ddkg" target="blank" className="hover:text-orange-500 text-2xl text-white font-bold font-mono tracking-wide text-center max-sm:tracking-tight">CHART WIF US</a>
              <a href="https://t.me/trumpwifhatcommunity" target="blank" className="hover:text-orange-500 text-2xl text-white font-bold font-mono tracking-wide text-center max-sm:tracking-tight">CHAT WIF US</a>
              <a href="https://x.com/trumpwifhatcto" target="blank" className="hover:text-orange-500 text-2xl text-white font-bold font-mono tracking-wide text-center max-sm:tracking-tight">TWEET WIF US</a>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="mt-32 max-sm:mt-48 flex-grow items-center justify-center bg-gray-900 text-white text-center py-20 sm:px-16">
          <h1 className="max-sm:flex-col max-sm:mx-8 max-sm:text-6xl text-8xl font-bold mb-16 font-mono tracking-wide">WHAT IS <span className="max-sm:text-6xl text-blue-500">$TRUMPWIF</span>?</h1>
          <p className="max-sm:text-2xl max-sm:mx-8 text-2xl lg:text-4xl mb-8 leading-tight"><b>
            <span>TRUMPWIF ISN'T </span><span ref={highlightRef} className="bg-transparent">LITERALLY JUST TRUMP WIF A HAT</span>, 
            {' '}
            <span ref={strikethroughRef} className="">
              IT'S A SYMBOL OF PROGRESS, FOR FUTURISTIC
              TRANSACTIONS, A BEACON FOR THOSE WHO THINK
              AHEAD. IT'S CLEAR THAT THE FUTURE BELONGS TO
              THOSE WHO EMBRACE INNOVATIONS LIKE WIF,
              TRANSCENDING BOUNDARIES & PAVING A NEW ERA
              IN FINANCE AND TECHNOLOGY.
            </span>
          </b></p>
        </div>

      {/* Image slider */}
      <div className="flex bg-gray-900 justify-center items-center pb-32">
        <div className="relative w-96 h-96">
          <img src="trumpwif.jpg" alt="trump wif hit" className="absolute top-0 left-0 w-full h-full object-cover" />
          <img src="trumpwifsmile.jpg" alt="trump wif no hat" className="absolute top-0 left-0 w-full h-full object-cover clip-path-50" ref={image2Ref} />
          <input type="range" min="0" max="100" defaultValue="15" className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-4/5" ref={sliderRef} />
        </div>
      </div>

      {/* Embedded Tweets */}
      <div className="w-full bg-gray-800 py-16">
        <div className="max-w-6xl mx-auto mb-32 mt-32">
          {/* <h2 className="text-4xl font-bold mb-8 text-center text-white font-mono">✅ Ansem approved</h2> */}
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h2 className="px-8 text-7xl font-bold mb-16 text-center text-white font-mono">
                WHAT IS TRUMPWIF? <u>BE FR</u> 
              </h2>
              <div className="w-[50%] max-sm:w-96 grow shrink-0 items-center justify-center bg-gray-700 p-6 rounded-lg shadow-lg min-w-[300px] min-h-[200px]">
                <TwitterTweetEmbed tweetId="1788442139480752415" />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center mt-64 m-16 space-x-16">
              <div className="w-[50%] max-sm:w-96 bg-gray-700 p-6 rounded-lg shadow-lg transform -rotate-3">
                  <TwitterTweetEmbed tweetId="1788480775551066395" />
              </div>
              <h2 className="max-sm:pt-16 flex italic text-4xl font-bold mb-8 text-center text-white font-mono">
                  ... it's like elon & doge on crack
              </h2>
            </div>
          </div>
        </div>
      </div>

      <Parallax blur={3} bgImage={'./trumpwifbanner.png'} bgImageAlt="patriotic banner wif hat" strength={-200}>
        <div className="h-[175px] object-contain" />
      </Parallax>

      {/* Trumpwifdog */}
      <div className="flex flex-col items-center bg-gray-900 py-16">
        <div className="mt-32 mb-32">
          <h2 className="text-7xl font-bold mb-16 text-center text-white font-mono tracking-tight underline decoration-underline-red">
            BE FR FR I'M SO SERIOUS
          </h2>
          <img src="trumpwifdog.png" alt="trump wif dog" className="p-16 h-960 object-cover bg-gray-800 rounded-full" />
        </div>
      </div>

      {/* Trumpwifrizz */}
      <div className="flex flex-col items-center bg-black py-16">
        <h2 className="mt-32 text-7xl font-bold mb-16 text-center text-white font-mono tracking-tight">
            LISTEN 👇
        </h2>
        <div className="mb-32">
          <img src="trumpwifrizz.jpg" alt="trump wif rizz" className="h-960 object-cover" />
          {/* <Parallax blur={0} bgImage={'./trumpwifrizz.jpg'} bgImageAlt="patriotic banner wif hat" strength={200}>
            <div className="object-cover" />
          </Parallax> */}
        </div>
      </div>

        {/* Footer */}
        <div className="px-8 bg-gray-800 text-white text-center py-8">
          <h2 className="text-md">TrumpWifHat is a community-run project and is not officially affiliated with Donald J. Trump.<span ref={strikethroughRef}></span></h2>
          <h3 className="text-xs mt-2 mb-2">hello@trumpwifhatcoin.com<span ref={strikethroughRef}></span></h3>
        </div>
      </div>
    </div>
  );
};

export default DogWifHat;